import router from '@/router/index'
import inFo from "../utils/inFo.js"

/**
 * 页面跳转传参校验
 * @param classname 页面跳转传参
 */
export function checkClass (classname) {
    let rt = false
    let classlist = JSON.parse(localStorage.getItem('classlist'))
    classlist.forEach(element => {
        if (element.ctype == classname) {
          rt = true
        }
        if (rt) return
    })
    return rt
}

/**
 * 浏览器
 */
export function getBrowser () {
  const curBrowser = {
    isCefBrowser: false
  }
  const isCefBrowser = (/(OHOS)/i.test(navigator.userAgent)) // Chromium Embedded Framework 是一个浏览器简称CEF
  curBrowser.isCefBrowser = isCefBrowser
  return curBrowser
}

/**
 * 页面跳转
 * 提供给悟空图像app调用的api
 * @param info classname
 */
export function letGo(info) {
  // 菜单跳转
  let productstype = localStorage.getItem('productstype')
  if (!productstype) {
    productstype = 1
  }
  try {
    if (checkClass(info)) {
      router.push({
        path: "/front/sortPage",
        query: {sort: info, type: info, search: ''}
      })
    } else {
      if (productstype === '16') {
        router.push({
          path: '/front/indexVisualHome' // 首页 编程版本
        })
      } else if (productstype === '14') {
        router.push({
          path: '/front/indexLevelHome' // 首页 联想版本
        })
      } else if (productstype === '10') {
        router.push({
          path: '/front/indexEduHome' // 首页 教育版本
        })
      } else {
        router.push({
          path: '/front/indexAppHome' // 首页
        })
      }
    }
  } catch (e) {}
}

/**
 * 登录
 * 提供给悟空图像app调用的api
 * @param token 登录凭证 token
 * @param pcversion 悟空图像app接口版本（此参数为了兼容旧版本）： 'v3' 最新版本； '' 旧版本
 */
export function onLogin(token, pcversion) {
  try {
    let loginfo = {
      token: token,
      pcversion: pcversion
    }
    inFo.emit('loginQt', loginfo)
  } catch (e) {
    console.log(e)
  }
}

/**
 * 退出登录
 * 提供给悟空图像app调用的api
 */
export function offLogin () {
  try {
    let loginfo = {
      token: null,
      pcversion: null
    }
    inFo.emit('loginQt', loginfo)
  } catch (e) {
    console.log(e)
  }
}

/**
 * 显示loading
 * 提供给悟空图像app调用的api
 */
export function onLoad () {
  try {
    inFo.emit('loadingDownload', true)
  } catch (e) {
    console.log(e)
  }
}

/**
 * 关闭loading
 * 提供给悟空图像app调用的api
 */
export function offLoad () {
  try {
    inFo.emit('loadingDownload', false)
  } catch (e) {
    console.log(e)
  }
}

